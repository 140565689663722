<template>
  <main class="screen default">
    <div class="screen-header">
      <h2 class="screen-title xs:mb-2 mb-0">{{ $t('view_activities.heading') }}</h2>
      <button @click="addActivity" class="btn primary">
        {{ $t('view_activities.button_add') }}
      </button>
    </div>

    <List :items="activities" v-slot="{ item }">
      <ActivityItem v-bind:activity="item" />
    </List>
  </main>
</template>

<script>
import ActivityItem from '@/components/ActivityItem';
import List from '@/components/List';
import { Route } from '@/router/index';
import { reverse } from 'ramda';

export default {
  name: 'ViewActivitiesScreen',
  components: {
    ActivityItem,
    List,
  },
  methods: {
    addActivity() {
      this.$router.push({ name: Route.ADD_ACTIVITY });
    },
  },
  computed: {
    activities() {
      return reverse(this.$store.state.activities);
    },
  },
};
</script>
