<template>
  <span class="flex justify-between items-center">
    {{ activity.title }}

    <button class="btn btn-sm shadow-none" v-on:click="deleteItem">&times;</button>
  </span>
</template>

<script>
import { REMOVE_ACTIVITY } from '@/store/action-types';

export default {
  name: 'ActivityItem',
  props: { activity: Object },
  methods: {
    deleteItem(event) {
      event.preventDefault();
      this.$store.dispatch(REMOVE_ACTIVITY, this.activity);
    },
  },
};
</script>
